import React, { useState, useEffect } from "react"
import styles from "src/styles/components/news-box.module.scss"
import { Grid } from "@material-ui/core"
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined"
import newsBox from "content/data/news-box.json"

const NEWS_BOX_PREFERENCE_KEY = "mindance:hide-news-box"

export default function NewsBox() {
  const { text, cta_text, cta_link, show_on_page } = newsBox

  const [showBox, setShowBox] = useState(false)

  useEffect(() => {
    const hidePreference = localStorage.getItem(NEWS_BOX_PREFERENCE_KEY)
    setShowBox(show_on_page && !hidePreference)
  }, [show_on_page])

  if (!showBox) {
    return null
  }

  return (
    <Grid
      alignContent="center"
      alignItems="center"
      xs={12}
      className={styles.root}
      container
    >
      <Grid container justify="center" item xs>
        <span>{text}</span>
        &nbsp;
        {cta_link && (
          <a target="_blank" rel="noopener noreferrer" href={cta_link}>
            {cta_text}
          </a>
        )}
      </Grid>
      <button
        onClick={() => {
          setShowBox(false)
          localStorage.setItem(NEWS_BOX_PREFERENCE_KEY, true)
        }}
        className={styles.closeIcon}
      >
        <HighlightOffOutlinedIcon fontSize="small" />
      </button>
    </Grid>
  )
}
