import React from "react"
import PropTypes from "prop-types"
import map from "lodash/map"
import Section from "./section"
import Navbar from "./navbar"
import NewsBox from "./news-box"
import SEO from "./seo"
import Layout from "./layout"
import NewsletterSubscriptionForm from "./newsletter-subscription-form"
import { Box } from "@material-ui/core"

export default function StaticPage(props) {
  const {
    pageContext: { page },
  } = props

  return (
    <Layout>
      <SEO title={page.name} />
      <NewsBox />
      <Navbar />
      {map(page.sections, (section, index) => (
        <Section key={`section-${index}`} section={section} index={index} />
      ))}
      <Box py={8}>
        <NewsletterSubscriptionForm type="b2b" />
      </Box>
    </Layout>
  )
}

StaticPage.propTypes = {
  pageContext: PropTypes.shape({
    page: PropTypes.object,
    sections: PropTypes.arrayOf(PropTypes.object),
  }),
}
